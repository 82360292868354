
import {
    createVNode
} from "vue";
import url from "@/assets/error/error";
import editorCharge from "./component/editor.vue";
import {
    PlusOutlined,
    ExclamationCircleOutlined,
    CalendarOutlined
} from "@ant-design/icons-vue";

export default {
    name: "AccountDeposit",
    components: {
        editorCharge,
        PlusOutlined,
        CalendarOutlined
    },
    data() {
        return {
            where: {},
            visible: false,
            // 驳回弹窗
            visibleVoid: false,
            // 填写原因
            voidForm: {},
            // 预览图片
            previewVisible: false,
            // 图片资源
            previewImage: "",

            url: "/user/deposit/list",
            current: {},
            columns: [{
                    key: "index",
                    width: 48,
                    customRender: ({
                        index
                    }) => this.$refs.table.tableIndex + index,
                },
                {
                    title: this.$t("deposit.fields.order_po_number"),
                    dataIndex: "order_po_number",
                    width: 220
                },
                {
                    title: this.$t("deposit.fields.value"),
                    dataIndex: "charge",
                    width: 120,
                    customRender: ({
                        record
                    }) => `${record.charge} ${record.currency}`
                },
                {
                    title: this.$t("押金"),
                    dataIndex: "deposit_price",
                    width: 120,
                    customRender: ({
                        record
                    }) => {
                        if (record.deposit_price === undefined) {
                            return "-"
                        }
                        return `${record.deposit_price} ${record.currency}`
                    }
                },
                {
                    key: "remark",
                    title: this.$t("share.remark"),
                    dataIndex: "remark",
                    slots: {
                        customRender: "remark",
                    }
                },
                {
                    title: this.$t("deposit.attachment"),
                    align: "center",
                    width: 100,
                    slots: {
                        customRender: "attachment",
                    }
                },
                {
                    title: this.$t("deposit.fields.created"),
                    dataIndex: "created",
                    width: 200,
                    align: "center"
                },
                {
                    title: this.$t("操作人"),
                    dataIndex: "creator",
                    width: 120,
                    align: "center"
                },
                {
                    title: this.$t("deposit.fields.audit_time"),
                    dataIndex: "audit_time",
                    width: 200,
                    align: "center",
                    customRender: ({
                        text
                    }) => text || '-'
                },
                {
                    title: this.$t("share.status"),
                    key: "status",
                    dataIndex: "status",
                    align: "center",
                    width: 100,
                    slots: {
                        customRender: "status_active"
                    }
                },
                {
                    key: "action",
                    title: this.$t("share.operation"),
                    dataIndex: "action",
                    align: "center",
                    width: 120,
                    slots: {
                        customRender: "action",
                    },
                }
            ],
            selection: [],
            form: {},
            // 统计
            total: {}
        };
    },
    methods: {
        parseData(res) {
            this.total.totalcharage = res.totalcharage;
            this.total.totaldeposit = res.totaldeposit;

            return res
        },
        // 处理错误图片
        handleError() {
            this.previewImage = url.base64URL;
        },
        // 展示附件
        showFile(row) {
            this.previewVisible = true;
            this.previewImage = process.env.VUE_APP_STS_FILE_URL + row.attachment;
        },
        // 删除功能
        handleDelete(row) {
            // 禁用功能
            if (row.status == '2') {
                return
            }
            if (row) {
                this.selection = [row];
            }
            if (!this.selection.length) {
                return this.$message.error('share.select_one_more_then');
            }

            this.$confirm({
                title: this.$t('share.tips'),
                content: this.$t('share.delete_confirm'),
                icon: createVNode(ExclamationCircleOutlined),
                maskClosable: true,
                onOk: () => {
                    const hide = this.$message.loading('share.requesting', 0);

                    this.$http.get(`/user/deposit/delete/${this.selection.map(d => d.id).join(',')}`).then(res => {
                        hide();
                        if (res.data.code === 0) {
                            this.$message.success(res.data.msg);
                            this.reload();
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    }).catch(e => {
                        hide();
                        this.$message.error(e.message);
                    });
                }
            });
        },
        reload() {
            this.selection = [];
            this.$refs.table.reload({
                page: 1,
            });
        },
        addCharge(value) {
            this.visible = true;
            this.form = value || {};
        },
        onClose() {
            this.visible = false;
        }
    }
};
